/* istanbul ignore file: todo add infinite scroll tests @guyse */

/* eslint-disable @typescript-eslint/unbound-method */
import React from 'react';
import autobind from 'auto-bind-es5';
import imagesLoaded from 'imagesloaded';

import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {DataHook as ProductImagesDataHook} from '../../../common/components/ProductItem/ProductImage/ProductImageOLD';
import {EmptyGallery} from './EmptyGallery/EmptyGallery';
import {IGalleryGlobalProps} from '../../galleryGlobalStrategy';
import {LoadMoreButton} from './LoadMoreButton/LoadMoreButton';
import {GridType, LoadMoreType} from '../../../types/galleryTypes';
import {withGlobals} from '../../../globalPropsContext';
import {DEFAULT_MOBILE_PRODUCTS_COUNT} from '../../../constants';
import {ProductListGrid} from './ProductListGrid';
import {ProductItemWithGlobals} from '../../../common/components/ProductItem/ProductItem';
import {Pagination, PaginationProps} from './Pagination';
import {Loader} from '../../../common/components/Loader/Loader';
import {hasScrollReachedElementBottom} from './utils/infiniteScrollUtils';
import {scrollTo} from '@wix/wixstores-client-core/dist/es/src/utils/scrollTo';
import {PaginationLinksForSeo} from './PaginationLinksForSeo';

export interface ProductListProps extends IGalleryGlobalProps, IProvidedTranslationProps {
  hasFilters: boolean;
}

interface ProductListState {
  inBrowser: boolean;
}

class ProductListComp extends React.Component<ProductListProps, ProductListState> {
  private imagesLoaded = false;
  private isInteractive = false;
  private focusedAt: number = -1;
  private scrollAfterUpdate = false;
  private loadMoreCalled = false;
  private readonly SCROLL_EVENT_NAME = 'scroll';
  private componentRef: HTMLDivElement;
  private readonly refCallback = (elem) => {
    this.componentRef = elem;
  };

  public constructor(props: ProductListProps) {
    super(props);
    this.state = {
      inBrowser: false,
    };
    autobind(this);
  }

  public componentDidMount(): void {
    const {loadMoreType, setTotalPages} = this.props.globals;

    if (loadMoreType === LoadMoreType.INFINITE) {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      window.addEventListener(this.SCROLL_EVENT_NAME, this.onScroll);
    }

    setTotalPages(this.totalPages);

    this.setState({inBrowser: true}, () => {
      imagesLoaded(document.querySelectorAll(`[data-hook="${ProductImagesDataHook.Images}"]`), () => {
        this.imagesLoaded = true;
        this.reportLoad();
      });
    });
  }

  private reportLoad() {
    if (this.props.globals.isInteractive && this.imagesLoaded) {
      this.props.globals.appLoadBI.loaded();
    }
  }

  public componentDidUpdate(prevProps: IGalleryGlobalProps) {
    if (!this.isInteractive && this.props.globals.isInteractive) {
      this.isInteractive = true;
      /* istanbul ignore next: hard to test it */
      this.props.globals.updateLayout && this.props.globals.updateLayout();
      this.reportLoad();
    }

    if (this.scrollAfterUpdate) {
      this.scrollToTop();
      this.scrollAfterUpdate = false;
    }

    this.focusedAt = prevProps.globals.focusedProductIndex;
    this.loadMoreCalled = false;
  }

  public componentWillUnmount() {
    const {loadMoreType} = this.props.globals;

    if (loadMoreType === LoadMoreType.INFINITE) {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      window.removeEventListener(this.SCROLL_EVENT_NAME, this.onScroll);
    }
  }

  public render() {
    const {products} = this.props.globals;

    return (
      <section data-hook="product-list" ref={this.refCallback} aria-label={this.props.t('galleryRegionSR')}>
        {products.length === 0 ? this.getEmptyList() : this.getProductList()}
      </section>
    );
  }

  private get isMobileOverride() {
    return this.props.globals.shouldShowMobile;
  }

  private get forceSingleColumn() {
    return this.isMobileOverride && this.props.globals.isHorizontalLayout;
  }

  private get maxProductsPerPage(): number {
    const {
      shouldUseAutoGridProductsCount,
      experiments: {isSameAmountOfProductsInMobile},
    } = this.props.globals;

    const {gallery_productsCount, galleryColumns, galleryRows} = this.props.globals.styleParams.numbers;
    switch (true) {
      case shouldUseAutoGridProductsCount:
        return gallery_productsCount;
      case this.isMobileOverride && !isSameAmountOfProductsInMobile:
        return DEFAULT_MOBILE_PRODUCTS_COUNT;
      default:
        return galleryColumns * galleryRows;
    }
  }

  private get totalPages(): number {
    const {totalProducts} = this.props.globals;
    return Math.ceil(totalProducts / this.maxProductsPerPage);
  }

  private getEmptyList() {
    return <EmptyGallery hasFilters={this.props.hasFilters} />;
  }

  private getFocusableItemIndex() {
    const {isFirstPage, focusedProductIndex} = this.props.globals;
    const prevFocusedProductIndex = this.focusedAt;

    if (!isFirstPage && focusedProductIndex !== prevFocusedProductIndex) {
      return this.props.globals.focusedProductIndex;
    }

    return -1;
  }

  private getNumberOfVisibleProducts(): number {
    if (this.gridType() === GridType.AUTO && this.props.globals.isFirstPage) {
      return this.props.globals.styleParams.numbers.gallery_productsCount;
    }

    if (this.props.globals.isFirstPage) {
      return this.maxProductsPerPage;
    }

    return this.props.globals.products.length;
  }

  private gridType() {
    return this.props.globals.isAutoGrid ? GridType.AUTO : GridType.MANUAL;
  }

  private getProductList() {
    const {products, styleParams} = this.props.globals;
    const {fixGalleryResponsiveIssue} = this.props.globals.experiments;
    const nextProducts = products.slice(0, this.getNumberOfVisibleProducts());

    return (
      <div>
        <ProductListGrid
          products={nextProducts}
          isMobileOverride={this.isMobileOverride}
          forceSingleColumn={this.forceSingleColumn}
          fixGalleryResponsiveIssue={fixGalleryResponsiveIssue}
          gridType={this.gridType()}
          renderKey={String(styleParams.numbers.gallery_productSize)}
          focusAt={this.getFocusableItemIndex()}
          ProductItem={ProductItemWithGlobals}
        />
        {this.renderPaginationLinksForSeo()}
        {this.renderLoadMore()}
      </div>
    );
  }

  private async onScroll() {
    const {
      globals: {productsRequestInProgress},
    } = this.props;

    if (this.loadMoreCalled || productsRequestInProgress || !this.shouldAllowLoadMore()) {
      return;
    }

    const productListElementRect = this.componentRef.getBoundingClientRect();
    const bodyRect = document.body.getBoundingClientRect();

    const hasScrollReachedProductsListBottom = hasScrollReachedElementBottom(
      productListElementRect,
      bodyRect,
      document.documentElement.scrollTop,
      window.innerHeight
    );

    if (hasScrollReachedProductsListBottom) {
      this.loadMoreCalled = true;
      await this.loadMore();
    }
  }

  private renderPaginationLinksForSeo() {
    const {loadMoreType, currentPage, nextPrevLinks, experiments} = this.props.globals;
    if (!experiments.isPaginationForSeoEnabled || loadMoreType === LoadMoreType.PAGINATION) {
      return;
    }

    return (
      <PaginationLinksForSeo totalPages={this.totalPages} currentPage={currentPage} nextPrevLinks={nextPrevLinks} />
    );
  }

  private renderLoadMore() {
    const {loadMoreType} = this.props.globals;

    switch (loadMoreType) {
      case LoadMoreType.PAGINATION:
        return this.renderPagination();
      case LoadMoreType.INFINITE:
        return this.renderInfiniteScrollLoader();
      case LoadMoreType.BUTTON:
      default:
        return this.shouldAllowLoadMore() && this.getLoadMoreButton();
    }
  }

  private shouldAllowLoadMore(): boolean {
    const {
      globals: {isFirstPage, hasMoreProductsToLoad, totalProducts},
    } = this.props;

    if (isFirstPage) {
      return this.maxProductsPerPage < totalProducts;
    }
    return hasMoreProductsToLoad;
  }

  private getLoadMoreButton() {
    return <LoadMoreButton loadMoreClicked={this.loadMore} />;
  }

  private async loadMore() {
    await this.props.globals.loadMoreProducts(this.getNumberOfVisibleProducts(), this.maxProductsPerPage);
  }

  private scrollToTop() {
    scrollTo({
      top: this.componentRef.getBoundingClientRect().top + window.pageYOffset - 50,
      left: 0,
      smooth: true,
    });
  }

  private renderPagination() {
    const {
      currentPage,
      totalProducts,
      handlePagination,
      styleParams: {
        booleans: {gallery_paginationFirstLastArrows},
      },
      paginationMode,
      linkForAllPages,
      experiments,
    } = this.props.globals;
    const showFirstLastNavButtons = gallery_paginationFirstLastArrows && totalProducts <= 10_000;

    const props: PaginationProps = {
      currentPage,
      paginationMode,
      totalPages: this.totalPages,
      showFirstLastNavButtons,
      handlePagination: (page) => {
        this.scrollAfterUpdate = true;
        handlePagination(page);
      },
      linkForAllPages,
      isPaginationForSeoEnabled: experiments.isPaginationForSeoEnabled,
    };

    return <Pagination {...props} />;
  }

  private renderInfiniteScrollLoader() {
    const {
      globals: {productsRequestInProgress},
    } = this.props;

    return productsRequestInProgress && <Loader />;
  }
}

export const ProductList = withGlobals(withTranslations()(ProductListComp));
